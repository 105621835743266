<template>
  <div v-if="show.form">
    <div class="mb-2">
      <h4 class="mb-1">Datos del destinatario</h4>
      <form-render :fields="fields" :key="keyFormRender1" :form.sync="form" ref="formRenderDelivery1"/>
    </div>
    <b-row class="border-top">
      <b-col cols="12" md="6">
        <h4 class="mt-2">Datos del origen</h4>
        <form-render :fields="fields2" :key="keyFormRender2" :form.sync="form" ref="formRenderDelivery2" containerButtonsClass="vx-col w-full md:w-1/4 container-button">
          <template #infoPudo>
            <label v-if="infoPudo">Dirección:</label>
            <div>
              {{infoPudo}}
            </div>
          </template>
          <template #infoAgency>
            <label v-if="infoAgency">Dirección:</label>
            <div>
              {{infoAgency}}
            </div>
          </template>
        </form-render>
      </b-col>
      <b-col cols="12" md="6">
        <h4 class="mt-2">Datos de distribucion</h4>
        <form-render :fields="fields3" :key="keyFormRender3" :form.sync="form" ref="formRenderDelivery3" containerButtonsClass="vx-col w-full md:w-1/4 container-button"></form-render>
      </b-col>
      <b-col cols="12" md="12">
        <div class="d-flex justify-content-end">
          <b-button ref="createReturnDeliveryButton" variant="warning" :disabled="disabled.sendForm" @click="sendForm('return')"><i class="fa fa-spinner fa-spin mr-1" aria-hidden="true" v-if="show.loadingSendForm" /> {{ theReturn ? 'Actualizar información' : 'Guardar y continuar'}}</b-button>
        </div>
        <div v-if="show.deliveryCreated" class="d-flex justify-content-end">
          <b-link class="ml-1 mt-1" @click="goToReturnCreated()">{{$t(`Ver devolución ${form.id ? '' : 'creada'}`)}}</b-link>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ReturnsService from '../../returns.service'
import internalLevels from '@/views/modules/envia-flash/create/internalLevels'
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services'
import { BLink } from 'bootstrap-vue'

export default {
  name: 'create-return',
  components: { BLink },
  props: {
    theReturn: { type: Object }
  },
  data () {
    return {
      infoPudo: '',
      infoAgency: '',
      form: {},
      fields: [],
      fields2: [],
      fields3: [],
      formFilter: {},
      optionsDistributionType: [
        { id: 'home', text: 'Domicilio', value: false },
        { id: 'pudo', text: 'Punto de retiro', value: false },
        { id: 'agency', text: 'Agencia de carrier', value: false }
      ],
      warehouse: [],
      keyFormRender1: 0,
      keyFormRender2: 0,
      keyFormRender3: 0,
      disabled: {
        sendForm: false
      },
      editing: false,
      show: {
        form: false,
        loadingSendForm: false,
        deliveryCreated: false
      },
      returnsService: new ReturnsService(this),
      baseService: new BaseServices(this),
      maxPricesByCountry: {
        1:{ 
          profile: {
            cenco: { warning: 500000, alert: 999999 },
            normal: { warning: 500000, alert: null }
          } 
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      // organizations: 'getOrganizationsGlobal',
      carriers: 'getCarriersReturns',
      warehouses: 'getWarehousesFlash',
      levels: 'getLevels',
      countries: 'getCountries'
    })
  },
  watch: {
    carriers () {
      this.setSelectOptions('carrier', this.fields3, this.carriers)
      if (this.form.id) this.setCarrierValue()
    },
    warehouses () {
      this.setSelectOptions('destination', this.fields, this.warehouses)
      if (this.form.id) this.setWarehouseValue()
    },
    theReturn () {
      this.setDeliveryToShow('watch')
    },
    mySession () {
      this.isSeller = !!['ecommerce', 'seller'].includes(this.mySession?.role)
      this.setInitialData(this.$route.params.id ? 'detail' : 'create', 'watch')
    },
    'form.price' (price) {
      //only for Chile in the meantime 
      const countryId = this.form.country?.country_id 
      if (countryId === 1) this.checkPrice(price, countryId)
    }
  },
  mounted () {
    this.isSeller = !!['ecommerce', 'seller'].includes(this.mySession?.role)
    this.setInitialData(this.$route.params.id ? 'detail' : 'create', 'mounted')
  },

  methods: {
    async setDeliveryToShow (from) {
      this.form = {
        ...this.form,
        type: this.theReturn?.type,
        id: this.theReturn?.id,
        shipper: this.theReturn?.shipper,
        company: { ...this.theReturn.shipper, text: this.theReturn.shipper.name },
        name: this.theReturn?.origin?.contact?.name,
        phone: this.theReturn?.origin?.contact?.phone,
        email: this.theReturn?.origin?.contact?.email,
        full_address: this.theReturn?.origin?.address?.full_address,
        information: this.theReturn?.origin?.address?.information,
        imported_id: this.theReturn?.order?.imported_id,
        tracking_number: this.theReturn?.carrier?.tracking_number,
        weight: this.theReturn?.order?.packages[0]?.weight,
        volume: this.theReturn?.order?.packages[0]?.volume,
        n_packages: this.theReturn?.order?.packages[0]?.quantity,
        price: this.theReturn?.order?.packages[0]?.price,
        width: this.theReturn.order?.packages[0]?.width,
        length: this.theReturn.order?.packages[0]?.length,
        height: this.theReturn.order?.packages[0]?.height,
        description: this.theReturn.order?.packages[0].info,
        zipcode: this.theReturn?.origin?.address.zip_code
      }
      this.form[this.theReturn.origin.type] = { ...this.theReturn.origin, text: this.theReturn.origin.code }
      internalLevels[this.theReturn.origin.address.country_code.toLowerCase()].display.map(level => {
        this.form[`level${level}Text`] = this.theReturn.origin.address[`level${level}`]
      })
      const companyData = await this.baseService.callService('getCompaniesListData', { search_by: this.form.company.name, calls: 1 })
        .then(({data}) => data.companies.filter(data => data.id === this.form.company.id)[0])
      this.form.company = { ...companyData, text: companyData.name1 }
      this.changeCompany('setDeliveryToShow', this.form.company)
      if (this.form.id) {
        const index = this.getElementIndex(this.fields, 'company')
        this.fields.splice(index, 0, { fieldType: 'FieldInput', name: 'company_id', label: this.$t('ID empresa'), placeholder: this.$t('ID'), containerClass: 'col-sm-12 col-md-1 container-info', disabled: true })
        const index2 = this.getElementIndex(this.fields3, 'imported_id')
        this.fields3.splice(index2 + 1, 0, { fieldType: 'FieldInput', name: 'tracking_number', label: this.$t('Orden de transporte del courier'), containerClass: 'col-sm-12 col-md-6 container-info', disabled: true })
      }
      if (Array.isArray(this.carriers) && this.carriers.length !== 0) this.setCarrierValue()
      if (Array.isArray(this.warehouses) && this.warehouses.length !== 0) this.setWarehouseValue()
      this.setDistributionType()
    },
    async setInitialData (type = null, from) {
      this.fields = [
        { fieldType: 'FieldSelect', label: this.$t('Empresas'), name: 'company', validation: 'required', allowIdSearch: true, persistSearch: true, searchOnType: { fx: e => this.getCompaniesOnSearch(e), nChars: 3, debounce: 600 }, containerClass: 'col-sm-12 col-md-3 col-lg-3 col-xl-3 container-info', change: this.changeCompany },
        { fieldType: 'FieldSelect', name: 'destination', label: this.$t('Lugar de destino / Bodega'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required', dependency: 'company',
          allowIdSearch: true, persistSearch: true, searchOnType: { fx: this.getWarehousesOnSearch, nChars: 3, debounce: 600 }, change: this.changeWarehouse
        }
      ]
      if (this.isSeller) {
        const session = this.$session?.get('cas_user')
        const companyData = await this.baseService.callService('getCompaniesListData', { search_by: session.shipper.name1, calls: 1 })
          .then(({data}) => data.companies.filter(data => data.id === session.shipper.id)[0])
        this.form = {
          company: { ...companyData, text: companyData.name1 }
        }
        const index = this.getElementIndex(this.fields, 'company')
        this.fields[index].disabled = true
        if (type === 'create') this.changeCompany('setInitialData', this.form.company)
      }
      this.fields2 = [
        { fieldType: 'FieldInput', name: 'name', label: this.$t('Nombre'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'phone', label: this.$t('Celular'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required' },
        { fieldType: 'FieldInput', type: 'email', name: 'email', label: this.$t('Correo'), containerClass: 'col-sm-12 col-md-6 container-info' },
        {
          fieldType: 'FieldRadio',
          name: 'distributionType',
          label: this.$t('Tipo de distribución'),
          containerClass: 'col-sm-12 container-info',
          validation: 'required',
          align: 'h',
          options: this.optionsDistributionType,
          change: this.changeDistributionType,
          disabled: false
        },
        { fieldType: 'FieldTextarea', name: 'information', label: this.$t('Observaciones'), containerClass: 'col-sm-12 container-info' }
      ]
      this.fields3 = [
        { fieldType: 'FieldInput', name: 'imported_id', label: this.$t('Nº de referencia'), containerClass: 'col-sm-12 col-md-6 container-info' },
        { name: 'line0', useSlot: true, skipLine: true, containerClass: 'col-sm-12' },
        { fieldType: 'FieldSelect', name: 'carrier', label: this.$t('Courier'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', change: this.changeCarrier, dependency: 'company'},
        { fieldType: 'FieldSelect', name: 'service', label: this.$t('Servicio'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', dependency: 'carrier' },
        { fieldType: 'FieldSelect', name: 'types', label: this.$t('Tipo'), containerClass: 'col-sm-12 col-md-6 container-info', dependency: 'carrier' },
        { fieldType: 'FieldSelect', name: 'attributes', label: this.$t('Atributo'), containerClass: 'col-sm-12 col-md-6 container-info', dependency: 'carrier', multiple: true, specialName: true },
        { fieldType: 'FieldInput', name: 'weight', label: this.$t('Peso (kg)'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'n_packages', label: this.$t('Nº de bultos'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'price', label: this.$t('Valor producto'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'height', label: this.$t('Alto (cm)'), containerClass: 'col-sm-12 col-md-4 container-info' },
        { fieldType: 'FieldInput', name: 'width', label: this.$t('Ancho (cm)'), containerClass: 'col-sm-12 col-md-4 container-info' },
        { fieldType: 'FieldInput', name: 'length', label: this.$t('Largo (cm)'), containerClass: 'col-sm-12 col-md-4 container-info' },
        { fieldType: 'FieldTextarea', name: 'description', label: this.$t('Descripción del producto'), containerClass: 'col-sm-12 container-info', validation: 'required' }
      ]
      if (Array.isArray(this.carriers) && this.carriers.length !== 0) {
        this.setSelectOptions('carrier', this.fields3, this.carriers)
        if (this.form.id) this.setCarrierValue()
      }
      if (Array.isArray(this.warehouses) && this.warehouses.length !== 0) {
        this.setSelectOptions('destination', this.fields, this.warehouses)
        if (this.form.id) this.setWarehouseValue()
      }
      this.setDistributionType()
      this.keyFormRender1++
      this.keyFormRender2++
      this.keyFormRender3++
      this.environment = this.returnsService.setEnvironment()
      if (this.theReturn?.id) this.setDeliveryToShow('mounted')
      if (!this.show.form) {
        this.$emit('emitWatcher', { view: true })
        this.show.form = true
      } 
    },
    setSelectOptions (name, fields, options) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) {
        fields[index].options = options
      }
      this.keyFormRender1++
      this.keyFormRender2++
      this.keyFormRender3++
    },

    async validateForm () {
      const value1 = await this.$refs.formRenderDelivery1.onlyCheckForm()
      const value2 = await this.$refs.formRenderDelivery2.onlyCheckForm()
      const value3 = await this.$refs.formRenderDelivery3.onlyCheckForm()
      return value1 && value2 && value3
    },

    async sendForm (type) {
      const checkForm = await this.validateForm()
      if (checkForm === false) return
      const payload = this.buildPayload(this.form, type)
      // if (payload.warehouse === '_NO_WAREHOUSE_') {
      //   this.$alert('No es posible editar debido a que el destino debe ser una bodega. Si es necesario, debe agregarla al listado de bodegas de su empresa')
      //   return
      // }
      this.disabled.sendForm = true
      this.show.loadingSendForm = true
      const data = {
        queryParams: payload,
        params: { shipper_id: payload.shipper.id, id: payload.id },
        headers: { 'x-origin': 'Formulario' },
        onSuccess: (res) => {
          this.disabled.sendForm = false
          this.show.loadingSendForm = false
          this.show.deliveryCreated = true
          this.deliveryIdCreated = res.data.id
          this.$bvToast.toast('Felicidades!', {
            title: 'Creación exitosa!',
            variant: 'success',
            autoHideDelay: 2000
          })
        },
        onError: () => {
          this.$bvToast.toast('Error!', {
            title: 'Ha ocurrido un error!',
            variant: 'danger',
            autoHideDelay: 5000
          })
          this.disabled.sendForm = false
          this.show.loadingSendForm = false
        }
      }
      if (payload.id) {
        data.name = 'updateReturn'
        data.params = { shipper_id: payload.shipper.id, id: payload.id }
      } else {
        data.name = 'postReturn'
        data.params = { shipper_id: payload.shipper.id }
      }
      this.$store.dispatch('fetchService', data)
    },
    verifyStatus () {
      if (this.theReturn.status.id === 4 || this.theReturn.status.id === 1) {
        [this.fields, this.fields2, this.fields3].map((x, index) => {
          x.map(y => {
            if (y.name === 'company' || y.name === 'company_id' || y.name === 'destination_address') {
              y.disabled = true
            } else {
              y.disabled = false
            }
          })
        })
        this.disabled.sendForm = false
      } else {
        [this.fields, this.fields2, this.fields3].map(x => {
          x.map(y => (y.disabled = true))
        })
        this.disabled.sendForm = true
      }
      this.keyFormRender1++
      this.keyFormRender2++
      this.keyFormRender3++
    },
    changeCarrier (name, value) {
      if (value === null) {
        this.form.service = null
        this.setSelectOptions('service', this.fields3, [])
        this.$emit('watchers', { changeMultipackage: false, cancelDelivery: false })
      } else {
        const services = value.services.filter(serv => serv.is_return)
        if (name === 'carrier') this.form.service = services[0]
        this.setSelectOptions('service', this.fields3, services)
        if (value.attributes) this.setSelectOptions('attributes', this.fields3, value.attributes)
        if (value.types) this.setSelectOptions('types', this.fields3, value.types)
        this.$emit('watchers', { changeMultipackage: !!value.multipackage, cancelDelivery: !!value.cancelable })
      }
    },
    setCarrierValue () {
      this.form.carrier = this.carriers.filter(el => this.theReturn?.carrier?.code?.toLowerCase() === el?.code?.toLowerCase())[0] || null
      if (this.theReturn?.carrier?.service) this.form.service = this.form?.carrier?.services?.filter(el => this.theReturn?.carrier?.service?.toLowerCase() === el?.code?.toLowerCase())[0] || null
      if (this.theReturn?.carrier?.attributes) {
        this.form.attributes = []
        this.theReturn.carrier.attributes.map(att => this.form?.carrier?.attributes?.map(attribute => { if (attribute?.code.toLowerCase() === att.toLowerCase()) return this.form.attributes.push(attribute) }))
      }
      if (this.theReturn?.carrier?.type) this.form.types = this.form?.carrier?.types?.filter(el => this.theReturn?.carrier?.type?.toLowerCase() === el?.code.toLowerCase())[0] || null
      this.changeCarrier(null, this.form.carrier)
    },
    changePudo (name, value) {
      value === null ?  this.infoPudo = null : this.infoPudo = `${value.address.full_address}`
      this.keyFormRender2++
    },
    getPudosOnSearch (value) {
      const queryParams = {
        search: value,
        company_id: this.form.company_id
      }
      return this.returnsService.callService('getPudosFlash', queryParams)
        .then(resp => {
          return resp.data.map(pudo => {
            return {
              ...pudo,
              id: pudo.code,
              text: `${pudo.name} - (${pudo.code})`
            }
          })
        })
    },
    getPudosCompanyOnSearch (value = null) {
      const queryParams = {
        'country.id': this.form.company.country_id,
        'address.place.level1.id': this.form[`level${internalLevels[this.form.company.country_code.toLowerCase()].required}`].id,
        'owner.organization.id': this.form.company.id,
        'owner.type': 'Seller,Organization'
      }
      if (value !== null) queryParams['name__like__or__code__like'] = value
      const service = 'getPudosForType'
      this.baseService.callService(service, queryParams)
        .then(resp => {
          const pudosCompany = resp.data.map(pudo => {
            pudo.text = `${pudo.name} (${pudo.code})`
            return pudo
          })
          this.setSelectOptions('pudo', this.fields2, pudosCompany)
        })
    },
    changeAgency (name, value) {
      value === null ?  this.infoAgency = null : this.infoAgency = `${value.address.full_address}`
      this.keyFormRender2++
    },
    getAgenciesOnSearch (value) {
      const queryParams = {
        search: value,
        shipper_id: this.form.company_id
      }
      return this.returnsService.callService('getAgenciesFlash', queryParams)
        .then(resp => {
          return resp.data.map(agency => {
            return {
              ...agency,
              id: agency.code,
              text: `${agency.name} - (${agency.code})`
            }
          })
        })
    },
    changeCarrierAgency (name, value) {
      this.form = {
        ...this.form,
        carrier: value,
        agency: null
      }
      this.infoAgency = null
      this.changeCarrier(null, value)
      if (value !== null) this.getPudosCarriersOnSearch(null)
    },
    getPudosCarriersOnSearch (value = null) {
      const queryParams = {
        'country.id': this.form.company.country_id,
        'address.place.level1.id': this.form[`level${internalLevels[this.form.company.country_code.toLowerCase()].required}`].id,
        'owner.type': 'Courier',
        'carrier.code': this.form.carrier.code
      }
      if (value !== null) queryParams['name__like__or__code__like'] = value
      const service = 'getPudosForType'
      return this.baseService.callService(service, queryParams)
        .then(resp => {
          const pudosCarriers = resp.data.map(el => {
            el.text = `${el.name} (${el.code})`
            return el
          })
          this.setSelectOptions('agency', this.fields2, pudosCarriers)
        })
    },
    getWarehousesOnSearch (value) {
      const queryParams = {
        search: value,
        shipper_id: this.form.company_id
      }
      return this.returnsService.callService('getWarehousesFlash', queryParams)
        .then(resp => {
          return resp.data.map(warehouse => {
            return {
              ...warehouse,
              id: warehouse.code,
              text: warehouse.name,
              addressWarehouse: warehouse.address.full_address,
              phoneWarehouse: warehouse.contact.phone,
              emailWarehouse: warehouse.contact.email
            }
          })
        })
    },
    changeWarehouse (name, value) {
      this.fields = this.fields.filter(el => !['destination_address', 'destination_place'].includes(el.name))
      if (value === null || value === undefined) {
        this.form.destination_address = null
        this.form.destination_place = null
      } else {
        this.form.destination_address = value.address.full_address
        this.form['destination_place'] = value.address[`level${this.form.country.required[0]}`]
        const texts = this.$i18nImport(`${this.form.country.country_code.toLowerCase()}.es`)[`${this.form.country.country_code.toLowerCase()}.es`]
        const label = texts[`Place_level${this.form.country.required[0]}`] ?? 'Localidad'
        const index = this.getElementIndex(this.fields, 'destination')
        this.fields.splice(index + 1, 0,
          { fieldType: 'FieldInput', name: 'destination_address', label: this.$t('Dirección de destino'), placeholder: this.$t('Dirección de destino'), containerClass: 'col-sm-12 col-md-3 container-info', disabled: true, validation: 'required'},
          { fieldType: 'FieldInput', name: 'destination_place', label: this.$t(`${label} de destino`), placeholder: this.$t(`${label} de destino`), containerClass: 'col-sm-12 col-md-2 container-info', disabled: true })
      }
      this.keyFormRender1++
    },
    setWarehouseValue () {
      if (this.theReturn.destination.code === '_NO_WAREHOUSE_') {
        this.form.destination = {
          ...this.theReturn.destination,
          text: 'NO BODEGA'
        }
        this.form.destination_address = this.theReturn.destination.address.full_address
      } else {
        const warehouse = this.warehouses.filter(el => this.theReturn.destination.code === el.code)[0]
        if (warehouse === undefined) {
          this.form.destination = { ...this.theReturn.destination, text: this.theReturn.destination.code }
        } else this.form.destination = warehouse
        this.changeWarehouse('setWarehouseValue', this.form.destination)
      }
    },
    setDistributionType () {
      if (this.theReturn?.origin?.type === 'pudo') {
        this.form.distributionType = this.optionsDistributionType[1]
        this.infoPudo = this.form.pudo.address?.full_address
      } else if (this.theReturn?.origin?.type === 'agency') {
        this.form.distributionType = this.optionsDistributionType[2]
        this.infoAgency = this.form.agency.address.full_address
      } else if (this.theReturn?.origin?.type === 'home') {
        this.form.distributionType = this.optionsDistributionType[0]
      } else {
        this.form.distributionType = null
        return
      }
      this.changeDistributionType('', this.form.distributionType)
      this.keyFormRender2++
    },
    changeDistributionType (name, value) {
      this.updateField(this.fields3, 'carrier', false)
      if (name === 'distributionType') {
        this.form = {
          ...this.form,
          carrier: null,
          service: null,
          attribute: null,
          carrier_agency: null,
          zipcode: null,
          pudo: null,
          agency: null,
          full_address: null
        }
        this.changePudo('', null)
        this.changeCarrierAgency('', null)
        if (this.selectorsLevel) Object.keys(this.selectorsLevel).map(level => this.form[level] = null)
      }
      this.fields2 = this.fields2.filter(el => !['pudo', 'agency', 'carrier_agency'].includes(el.name)).filter(el => el.name.indexOf('level') === -1 && !['place', 'place_1', 'full_address', 'zipcode', 'infoPudo', 'infoAgency'].includes(el.name))
      let index = this.fields2.findIndex(el => el.name === 'distributionType')
      if (this.form.country.country_code.toUpperCase() === 'MX') {
        this.fields2.splice(index + 1, 0,
          { fieldType: 'FieldSelect', name: 'zipcode', label: this.$t('Código postal'), containerClass: 'col-sm-12 col-md-6 container-info', searchOnType: { fx: this.searchZipCode, nChars: 3, debounce: 300 }, validation: 'required', change: this.handleSelectZipcode }
        )
        index++
      }
      if (value.id === 'pudo') {
        this.fields2.splice(index + 1, 0, {
          fieldType: 'FieldSelect', name: 'pudo', label: this.$t('Punto de retiro'), containerClass: 'col-sm-12 col-md-6 container-info', change: this.changePudo,
          validation: 'required', dependency: `level${internalLevels[this.form.company.country_code.toLowerCase()].required}` // searchOnType: { fx: this.getPudosCompanyOnSearch, nChars: 3, debounce: 300 },
        },
        { name: 'infoPudo', useSlot: true, containerClass: 'col-sm-12 col-md-6 container-info' }
        )
      } else if (value.id === 'agency') {
        this.updateField(this.fields3, 'carrier', true)
        this.fields2.splice(index + 1, 0, 
          { fieldType: 'FieldSelect', name: 'carrier_agency', label: this.$t('Courier'), containerClass: 'col-sm-12 col-md-6 container-info', options: this.carriers,
            validation: 'required', change: this.changeCarrierAgency, dependency: `level${internalLevels[this.form.company.country_code.toLowerCase()].required}` },
          { fieldType: 'FieldSelect', name: 'agency', label: this.$t('Agencia de courier'), containerClass: 'col-sm-12 col-md-6 container-info', change: this.changeAgency,
            validation: 'required', dependency: 'carrier_agency' }, // searchOnType: { fx: this.getPudosCarriersOnSearch, nChars: 3, debounce: 300 },
          { name: 'infoAgency', useSlot: true, containerClass: 'col-sm-12 col-md-6 container-info' }
        )
      } else if (value.id === 'home') {
        if (this.form.country?.country_code?.toUpperCase() !== 'MX') {
          this.fields2.splice(index + 1, 0,
            { fieldType: 'FieldInput', name: 'zipcode', label: this.$t('Código postal'), containerClass: 'col-sm-12 col-md-6 container-info' },
            { fieldType: 'FieldInput', name: 'full_address', label: this.$t('Dirección'),  containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', validation: 'required' }
          )
        } else {
          this.fields2.splice(index + 1, 0,
            // { fieldType: 'FieldSelect', name: 'zipcode', label: this.$t('Código postal'), containerClass: 'col-sm-12 col-md-6 container-info', searchOnType: { fx: this.searchZipCode, nChars: 3, debounce: 300 }, validation: 'required', change: this.handleSelectZipcode },
            { fieldType: 'FieldInput', name: 'full_address', label: this.$t('Dirección'),  containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', validation: 'required' }
          )
        }
      }
      const country = {
        code: this.form.country.country_code.toUpperCase(),
        required: internalLevels[this.form.country.country_code.toLowerCase()].required,
        display: internalLevels[this.form.country.country_code.toLowerCase()].display
      }
      this.addPlacesLevelFields(country)
      if (this.theReturn?.status) this.verifyStatus()
      this.keyFormRender2++
    },
    addPlacesLevelFields (country) {
      this.selectorsLevel = this.$generatePlacesLevels(this.$generateArrayPlacesFromTree(this.levels), Math.max(country.required), country.display)
      const texts = this.$i18nImport(`${country.code.toLowerCase()}.es`)[`${country.code.toLowerCase()}.es`]
      let indexSplice = this.fields2.findIndex(el => el.name === 'distributionType') + 1
      if (country.code.toUpperCase() === 'MX') {
        indexSplice = this.fields2.findIndex(el => el.name === 'zipcode') + 1
        this.selectorsLevel = { level4: this.selectorsLevel.level4, level1: this.selectorsLevel.level1, level2: this.selectorsLevel.level2}
      }
      Object.keys(this.selectorsLevel).map((key, index) => {
        if (country.code.toUpperCase() === 'MX') {
          const myField = { name: key, fieldType: 'FieldSelect', label: texts[`Place_${key}`], containerClass: 'col-sm-12 container-info col-md-6' }
          if (key === 'level4') {
            myField.dependency = 'zipcode'
            myField.useLabel = true
            myField.change = this.changeOnLevel4
          } else {
            myField.dependency = true
          }
          myField.validation = (country.required || []).includes(parseInt(key.split('level')[1])) ? 'required' : ''
          const indexFields = this.fields2.findIndex(el => el.name === myField.name)
          if (indexFields === -1) this.fields2.splice(indexSplice, 0, myField)
          if (this.form[`${key}Text`]) this.form[key] = { text: this.form[`${key}Text`], id: this.form[`${key}Text`] }
        } else {
          const myField = { fieldType: 'FieldSelect', name: key, label: texts[`Place_${key}`], containerClass: 'col-sm-12 container-info col-md-6', options: this.selectorsLevel[key].sort((a, b) => a.name > b.name ? 1 : -1) }
          if (index !== 0) {
            myField.dependency = Object.keys(this.selectorsLevel)[index - 1]
          }
          if (index !== Object.keys(this.selectorsLevel).length - 1) {
            myField.change = (name, value) => this.changePlaceLevel(name, value, Object.keys(this.selectorsLevel)[index + 1])
          } else {
            myField.change = (name, value) => this.changePlaceLevel(name, value, Object.keys(this.selectorsLevel)[index + 1])
          }
          myField.validation = (country.required || []).includes(parseInt(key.split('level')[1])) ? 'required' : ''
          const indexFields = this.fields2.findIndex(el => el.name === myField.name)
          if (indexFields === -1) this.fields2.splice(indexSplice, 0, myField)
          if (this.form[`${key}Text`]) this.form[key] = this.selectorsLevel[key].filter(e => e.text === this.form[`${key}Text`])[0]
        }
        indexSplice++
      })
    },
    changePlaceLevel (name, value, next) {
      if (this.form.distributionType.id === 'agency') {
        this.form.carrier_agency = null
        this.form.agency = null
        this.changeCarrierAgency('', null)
      } else if (this.form.distributionType.id === 'pudo') {
        this.form.pudo = null
        this.changePudo('', null)
      }
      if (!value) {
        this.form[next] = null
        return
      }
      const index = this.fields2.findIndex(el => el.name === next)
      if (next && index !== -1) this.fields2[index].options = value[next].sort((a, b) => a.name > b.name ? 1 : -1)
      if (this.form.distributionType.id === 'pudo') {
        this.getPudosCompanyOnSearch(null)
      } 
      this.keyFormRender2++
    },
    buildPayload (form, type) {
      const delivery = {
        carrier: {
          code: form.carrier?.code || null,
          service: form.service?.code || null
        },
        order: {
          imported_id: form.imported_id || '',
          packages: [{
            weight: form.weight || null,
            volume: form.company?.country_code?.toUpperCase() === 'CO' && !form.company?.mkp_customization?.replace_null_volume ? null : `${form?.volume || ''}`.replace(/,/g, '.') || null,
            width: form.width || null,
            height: form.height || null,
            length: form.length || null,
            price: form.price || null,
            quantity: form.n_packages || null,
            info: form.description || null
          }]
        },
        shipper: {
          code: form.company.code,
          id: form.company.id
        }
      }
      if (form.id) {
        delivery.type = form.type
        delivery.id = form.id
      } else {
        delivery.type = type
      }
      if (form.attributes && Array.isArray(form.attributes) && form.attributes.length !== 0) {
        delivery.carrier.attributes = form.attributes?.map(att => att.code)
      }
      if (form.types) {
        delivery.carrier.types = form.types?.code
      }
      if (delivery.type === 'delivery') {
        delivery.destination = {
          type: form.destination?.type,
          code: form.destination?.code
        }
      } else {
        delivery.destination = {
          type: form.destination.type,
          code: form.destination.code
        }
      }
      if (form.distributionType.id === 'home') {
        const levels = Object.keys(form).filter(key => ['level1', 'level2', 'level3', 'level4'].includes(key))
        delivery.origin = {
          type: form.distributionType.id,
          code: null,
          contact: {
            name: form.name,
            email: form.email,
            phone: form.phone
          },
          address: {
            full_address: form.full_address,
            zip_code: form.zipcode?.id || form.zipcode || null,
            information: form.information
          }
        }
        levels.map(level => form[`${level}`] ? delivery.origin.address[`${level}`] = form[`${level}`].text : delivery.origin.address[`${level}`] = null)
      } else {
        delivery.origin = {
          type: form.distributionType.id,
          code: form[form.distributionType.id]?.code,
          // address:  form[form.distributionType.id].address,
          contact: {
            name: form.name,
            email: form.email,
            phone: form.phone
          }
        }
      }
      return delivery
    },
    getCountryId (country_code) {
      switch (country_code) {
      case 'CL':
        return 1
      case 'AR':
        return 5
      case 'CO':
        return 3
      case 'PE':
        return 4
      case 'MX':
        return 2
      case 'EC':
        return 6
      default:
        return null
      }
    },
    // Endpoint with API-PLATFORM
    getCompaniesOnSearch(value) {
      const queryParams = {
        search_by: value,
        calls: 1
      }
      return this.baseService.callService('getCompaniesListData', queryParams)
        .then(({data}) => {
          const noMKPData = data.companies.filter(data => !data.marketplace).map(company => {
            return {
              ...company,
              text: company.name1
            }
          })
          return noMKPData
        })
    },
    changeCompany (name, value) {
      const index = this.getElementIndex(this.fields3, 'weight')
      this.fields3 = this.fields3.filter(field => field.name !== 'volume')
      if (!value) {
        const countryCode = this.form.beforeCountryCode
        this.form = {
          beforeCountryCode: countryCode
        }
        this.setInitialData()
        this.changeWarehouse()
        return
      }
      if (!['CO'].includes(value.country_code.toUpperCase()) || (['CO'].includes(value.country_code.toUpperCase()) && value.mkp_customization?.replace_null_volume)) {
        this.fields3.splice(index + 1, 0, { fieldType: 'FieldInput', name: 'volume', label: this.$t('Volumen (m³)'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required' })
      }
      this.form = {
        ...this.form,
        company_id: value.id,
        country: {
          country_code: value.country_code,
          country_id: value.country_id,
          required: internalLevels[value.country_code.toLowerCase()].required,
          display: internalLevels[value.country_code.toLowerCase()].display
        }
      }
      const services = [
        { name: 'getCarriersReturns', queryParams: {shipper_id: value?.id}, hideAlert: true },
        { name: 'getWarehousesFlash', queryParams: { shipper_id: value.id }, hideAlert: true }
      ]
      if ((!this.form.beforeCountryCode || (!!this.form.beforeCountryCode && this.form.beforeCountryCode.toLowerCase() !== value.country_code.toLowerCase())) && value.country_code.toLowerCase() !== 'mx') {
        this.form.beforeCountryCode = value.country_code.toUpperCase()
        services.push({ name: 'getLevels', params: { country: value.country_code.toLowerCase()}, hideAlert: true })
      } else if (!this.form.beforeCountryCode || (this.form.beforeCountryCode?.toLowerCase() !== value.country_code.toLowerCase())) {
        this.form.beforeCountryCode = value.country_code.toUpperCase()
        this.$store.commit('setLevels', [])
      }
      this.$store.dispatch('fetchMultipleServices', {services, onSuccess: () => { this.form.distributionType = this.optionsDistributionType[0]; this.changeDistributionType('', this.form.distributionType) }, onError: (err) => console.error(err)})
    },
    searchZipCode (value) {
      const params = {
        country_id: this.form.country.country_id
      }
      const queryParams = {
        str: value
      }
      return this.returnsService.callService('getZipcode', queryParams, params)
        .then(resp => {
          const response = resp.data.zip_codes.map(val => ({'name': val, 'id': val}))
          response.map((row) => {
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name
          })
          return response
        })
    },
    handleSelectZipcode (name, value) {
      const index = this.getElementIndex(this.fields2, 'level4')
      if (!value) {
        this.form.level4 = null
        delete this.form.level1
        delete this.form.level2
        return
      }
      const params = {
        country: this.form.country.country_code.toLowerCase()
      }
      const queryParams = {
        zip_code: value.id
      }
      this.fields2[index].useSkeleton = true
      this.returnsService.callService('getLevels', queryParams, params)
        .then(resp => {
          this.form.level1 = { id: resp.data.level1[0].name, text: resp.data.level1[0].name }
          this.form.level2 = { id: resp.data.level1[0].level2[0].name, text: resp.data.level1[0].level2[0].name }
          this.form.level4 = null
          const places = resp.data.level1[0].level2[0].level3[0].level4.map(level => {
            return {
              ...level,
              text: level.name
            }
          })
          this.fields2[index].useSkeleton = false
          this.setSelectOptions('level4', this.fields2, places)
        })
    },
    changeOnLevel4(name, value) {
      this.keyFormRender2++
    },
    getElementIndex(array, value) {
      return array.findIndex(fieldsData => fieldsData.name === value)
    },
    goToReturnCreated () {
      window.open(`${this.environment}/returns/${this.deliveryIdCreated}`, '_blank')
    },
    updateField (fields, name, value = true, prop = 'disabled') {
      const index = fields.findIndex((el) => el.name === name)
      fields[index][prop] = value
    },
    isCenco(session) {
      return session?.special_profile === 'cenco'
    },
    checkPrice(price, country_id) {
      const countryId = country_id
      const limits = this.isCenco(this.mySession) ?  this.maxPricesByCountry[countryId]?.profile.cenco : this.maxPricesByCountry[countryId]?.profile.normal
 
      if (parseInt(price?.replace(/\./g, '')) > parseInt(limits?.warning)) {
        this.$warning('El precio del producto supera el valor normal de un envío', '', '!Advertencia!')
      }
      if (limits?.alert && parseInt(price?.replace(/\./g, '')) > parseInt(limits?.alert)) {
        this.$alert('El precio del producto supera el valor normal de un envío', '', 'Error')
        this.$refs.createReturnDeliveryButton.disabled = true
      } else {
        this.$refs.createReturnDeliveryButton.disabled = false
      }
    }
  }
}
</script>
<style lang="scss"></style>
